import logo from './logo.svg';
import './App.css';

/*
  Load Firebase
 */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {Button, Paper, TextField} from "@mui/material";
import SignInSide from "./examples/SignInSide";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import * as React from "react";
import {createTheme, styled, ThemeProvider, useTheme} from '@mui/material/styles';
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Calculator from "./calculator";
import HeaderTwo from "./examples/HeaderTwo";
import theme from "./assets/theme";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCB2PB-0Df728KGLzQvhQX_gZPZO9jcvOc",
    authDomain: "co-owner-calculator.firebaseapp.com",
    projectId: "co-owner-calculator",
    storageBucket: "co-owner-calculator.appspot.com",
    messagingSenderId: "1073675930666",
    appId: "1:1073675930666:web:774bc8c7ac6e610f1f8753",
    measurementId: "G-SELFYPKBGP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

/*
  Core App
 */

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div>
                <HeaderTwo />
                <Calculator/>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default App;
