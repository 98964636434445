import { useEffect, useRef } from "react";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "../components/MKBox";
import MKButton from "../components/MKButton";
import MKTypography from "../components/MKTypography";

// Images
import bgImage from "../assets/images/bg-about-us.jpg";

function HeaderTwo() {
    const typedJSRef = useRef(null);

    // Setting up typedJS
    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: ["with friends", "with family", "with anyone"],
            typeSpeed: 70,
            backSpeed: 70,
            backDelay: 200,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    return (
        <MKBox component="header" position="relative">
            <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
                <Container>
                    <Grid container flexDirection="row" alignItems="center">
                        <MKButton
                            variant="outlined"
                            color="white"
                            sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
                        >
                            <MKBox component="i" color="white" className="fas fa-bars" />
                        </MKButton>
                        <MKBox
                            component="ul"
                            display={{ xs: "none", lg: "flex" }}
                            p={0}
                            m={0}
                            sx={{ listStyle: "none" }}
                        >
                            <MKBox component="li">
                                <MKTypography
                                    component={Link}
                                    href="#"
                                    variant="button"
                                    p={1}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <MKBox component="i" color="white" className="fab fa-twitter" />
                                </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                                <MKTypography
                                    component={Link}
                                    href="#"
                                    variant="button"
                                    p={1}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <MKBox component="i" color="white" className="fab fa-facebook" />
                                </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                                <MKTypography
                                    component={Link}
                                    href="#"
                                    variant="button"
                                    p={1}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <MKBox component="i" color="white" className="fab fa-instagram" />
                                </MKTypography>
                            </MKBox>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox
                display="flex"
                alignItems="center"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.info.main, 0.5), rgba(gradients.info.state, 0.5))}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        mx="auto"
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Tired of paying rent? Find out how much you can save by co-owning <span ref={typedJSRef} />
                        </MKTypography>
                        <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
                            Use this handy calculator to compare the costs of renting vs potential home co-ownership.
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
        </MKBox>
    );
}

export default HeaderTwo;