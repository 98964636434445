import logo from './logo.svg';
import './App.css';

/*
  Load Firebase
 */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {Button, InputAdornment, Paper, TextField} from "@mui/material";
import SignInSide from "./examples/SignInSide";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
//import AttachMoneyIcon from "@mui/icons-material/LockOutlined";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import * as React from "react";
import {createTheme, styled, ThemeProvider, useTheme} from '@mui/material/styles';
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import {VictoryChart, VictoryTheme, VictoryAxis, VictoryLine, VictoryLegend, VictoryVoronoiContainer, VictoryTooltip} from "victory";
import house_logo from './house.jpg';

/*
  Core App
 */

const drawerWidth = 240;

const theme = createTheme();

const data = [
    {quarter: 1, earnings: 13000},
    {quarter: 2, earnings: 16500},
    {quarter: 3, earnings: 14250},
    {quarter: 4, earnings: 19000}
];

class Calculator extends React.Component {
    constructor(props) {
        super(props);

        let rent_data = [];

        let expenditure_data = [];

        let asset_data = [];

        this.state = {average_rent_paid: 0,
            weekly_loan_repayment: "",
            total_benefit: "",
            mean_benefit: "",
            chart_asset_value: rent_data,
            chart_rent: expenditure_data,
            chart_expenditure: asset_data,
            show_chart: false,

        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    set_blank = () => {
        this.setState({
            total_benefit: "",
            mean_benefit: "",
            weekly_loan_repayment: "",
            show_chart: false
        });
    }



    calculate_value = (average_rent_paid, expected_years_renting, total_amount_borrowed, avg_interest_rate, coowner_count, duration_of_loan, total_deposit, asset_appreciation, years_before_selling) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        let total_rent_paid = average_rent_paid * expected_years_renting * 52;
        console.log("Total Rent Paid: ", total_rent_paid);

        let monthly_interest = avg_interest_rate/12;
        console.log("monthly_interest: ", monthly_interest);

        let individual_amount_borrowed = total_amount_borrowed / coowner_count;

        let a = monthly_interest * (Math.pow(1+monthly_interest, duration_of_loan));
        console.log("a", a);

        let b = (Math.pow(1+monthly_interest,duration_of_loan))-1;
        console.log('b', b);

        let total_monthly_repayment = total_amount_borrowed * a / b;
        console.log("monthly_repayment: ", total_monthly_repayment);

        let total_repayment = total_monthly_repayment * duration_of_loan;
        console.log("total_repayment: ", total_repayment);

        let individual_monthly_repayment = total_monthly_repayment / coowner_count;

        let repayment_contribution = individual_monthly_repayment * expected_years_renting * 12;
        console.log("repayment_contribution: ", repayment_contribution);

        let contribution_percentage = repayment_contribution / total_repayment;
        console.log("contribution_percentage: ", contribution_percentage);

        let initial_asset_value = parseInt(total_amount_borrowed) + parseInt(total_deposit);
        console.log("initial_asset_value: ", initial_asset_value);

        let final_asset_value = initial_asset_value * Math.pow(1+asset_appreciation,years_before_selling);
        console.log("final_asset_value: ", final_asset_value);

        let individual_asset_value = final_asset_value * contribution_percentage;
        console.log("individual_asset_value: ", individual_asset_value);

        let net_asset_change = individual_asset_value - repayment_contribution + total_rent_paid;
        console.log("net_asset_change: ", net_asset_change);

        let rent_data = [];
        let asset_data = [];
        let expenditure_data = [];

        for(let i=1; i < parseInt(years_before_selling)+1; i++){
            let contribution_at = individual_monthly_repayment * Math.min(i, expected_years_renting) * 12;
            let contribution_percentage = contribution_at / total_repayment;
            let asset_value = initial_asset_value * Math.pow(1+asset_appreciation, i);
            let asset_at = asset_value * contribution_percentage;

            rent_data.push({x:i, y:average_rent_paid*-52*Math.min(i, expected_years_renting)});
            asset_data.push({x:i, y:asset_at});
            expenditure_data.push({x:i, y:contribution_at});
        }

        console.log("Years before Selling", years_before_selling);
        console.log("Rent Data:", rent_data);
        console.log("Asset Data:", asset_data);
        console.log("Expenditure Data:", expenditure_data);

        this.setState({
            weekly_loan_repayment: formatter.format(individual_monthly_repayment/4),
            total_benefit: formatter.format(net_asset_change),
            mean_benefit: formatter.format(net_asset_change/years_before_selling),
            show_chart: true,
            chart_rent: rent_data,
            chart_expenditure: asset_data,
            chart_asset_value: expenditure_data
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        let average_rent_paid = parseInt(this.state.average_rent_paid);
        console.log("average_rent_paid", average_rent_paid);
        if(average_rent_paid == null){
            this.set_blank();
            return;
        }
        let expected_years_renting = parseInt(this.state.expected_years_renting);
        if(expected_years_renting == null){
            this.set_blank();
            return;
        }

        let total_amount_borrowed = parseInt(this.state.total_amount_borrowed);
        if(total_amount_borrowed == null){
            this.set_blank();
            return;
        }

        let total_deposit = parseInt(this.state.total_deposit);
        if(total_deposit == null){
            this.set_blank();
            return;
        }

        let coowner_count = parseInt(this.state.coowner_count);
        if(coowner_count == null){
            this.set_blank();
            return;
        }

        let duration_of_loan = parseInt(this.state.duration_of_loan);
        if(duration_of_loan == null){
            this.set_blank();
            return;
        }

        let avg_interest_rate = parseInt(this.state.avg_interest_rate) / 100;
        if(avg_interest_rate == null){
            this.set_blank();
            return;
        }

        let asset_appreciation = parseInt(this.state.asset_appreciation) / 100;
        if(asset_appreciation == null){
            this.set_blank();
            return;
        }

        let years_before_selling = parseInt(this.state.years_before_selling);
        if(years_before_selling == null){
            this.set_blank();
            return;
        }

        this.calculate_value(average_rent_paid, expected_years_renting, total_amount_borrowed, avg_interest_rate, coowner_count, duration_of_loan, total_deposit, asset_appreciation, years_before_selling);
    };



    render() {
        return (
            <div>

                <Grid container component="main" sx={{height: '100vh'}}>

                    <Grid
                        item
                        xs={5}
                        sm={5}
                        md={5}
                        sx={{
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                                <AttachMoneyIcon/>
                            </Avatar>

                            <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{mt: 1}}>
                                <Typography component="h1" variant="h5">
                                    Renting
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="average_rent_paid"
                                    label="Average Rent Paid ($/wk)"
                                    name="average_rent_paid"
                                    autoFocus
                                    onChange={(e)=>{this.setState({average_rent_paid: e.target.value.toLocaleString()})}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="expected_years_renting"
                                    label="Expected years renting"
                                    autoFocus
                                    onChange={(e)=>{this.setState({expected_years_renting: e.target.value})}}
                                />

                                <hr/>

                                <Typography component="h1" variant="h5">
                                    Co-Owning
                                </Typography>

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="total_amount_borrowed"
                                    label="Total Amount Borrowed ($)"
                                    autoFocus
                                    onChange={(e)=>{this.setState({total_amount_borrowed: e.target.value})}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="total_deposit"
                                    label="Total Deposit ($)"
                                    autoFocus
                                    value={this.state.total_deposit}
                                    onChange={(e)=>{this.setState({total_deposit: e.target.value.toLocaleString()})}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                <TextField required fullWidth margin="normal" id="coowner_count"
                                           label="# of co-owners" variant="outlined"
                                           onChange={(e)=>{this.setState({coowner_count: e.target.value})}}
                                />
                                <TextField required fullWidth margin="normal" id="duration_of_loan"
                                           label="Duration of Loan (months)" variant="outlined"
                                           onChange={(e)=>{this.setState({duration_of_loan: e.target.value})}}
                                />
                                <TextField required fullWidth margin="normal" id="avg_interest_rate"
                                           label="Average Interest Rate over loan period (%)" variant="outlined"
                                           onChange={(e)=>{this.setState({avg_interest_rate: e.target.value})}}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                           }}
                                />
                                <TextField required fullWidth margin="normal" id="asset_appreciation"
                                           label="Asset Appreciation (%)" variant="outlined"
                                           onChange={(e)=>{this.setState({asset_appreciation: e.target.value})}}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                           }}
                                />
                                <TextField required fullWidth margin="normal" id="years_before_selling"
                                           label="Years before selling" variant="outlined"
                                           onChange={(e)=>{this.setState({years_before_selling: e.target.value})}}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Calculate
                                </Button>
                            </Box>

                            <hr/>
                        </Box>
                    </Grid>

                    <Grid item xs={7} sm={7} md={7} component={Paper} elevation={6} square  display="flex" alignItems="center" justifyContent="center">
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {!this.state.show_chart &&
                                <Box>
                                    <img src={house_logo} style={{height:"200px", width:"250px"}} alt="logo" />
                                    <Typography component="h1" variant="h5">
                                        Compare the financial cost of renting vs co-owning
                                    </Typography>
                                </Box>
                            }
                            {this.state.show_chart &&
                                <div>
                                    <Typography component="h1" variant="h5">
                                        Comparison Results
                                    </Typography>
                                    <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{mt: 1}}>
                                        <Typography component="h1" variant="h3"
                                                    sx={{
                                                        my: 4
                                                    }}>
                                            {this.state.total_benefit} earned
                                            over {this.state.years_before_selling} years
                                        </Typography>
                                        <Typography component="h1" variant="h6"
                                                    sx={{
                                                        my: 4
                                                    }}>
                                            {this.state.mean_benefit} per person per year
                                        </Typography>
                                        <Typography component="h1" variant="h6"
                                                    sx={{
                                                        my: 4
                                                    }}>
                                            Weekly Rent: ${this.state.average_rent_paid}
                                        </Typography>
                                        <Typography component="h5" variant="h6"
                                                    sx={{
                                                        my: 4
                                                    }}>
                                            Weekly Loan Repayment: {this.state.weekly_loan_repayment}
                                        </Typography>

                                        <hr/>
                                        <Typography component="h1" variant="h5">
                                            Comparison Over Time
                                        </Typography>
                                        <VictoryChart
                                            // adding the material theme provided with Victory
                                            theme={VictoryTheme.material}
                                            domainPadding={20}
                                            containerComponent={
                                                <VictoryVoronoiContainer voronoiDimension="x"
                                                                         labels={({ datum }) => `$${Math.round(datum.y / 1000)}k`}
                                                                         labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{fill: "white"}}/>}
                                                />
                                            }
                                        >
                                            <VictoryAxis
                                                dependentAxis
                                                tickFormat={(x) => (`$${x / 1000}k`)}
                                                style={{
                                                    tickLabels: {fontSize: 7.5}
                                                }}
                                            />
                                            <VictoryAxis
                                                crossAxis
                                                style={{
                                                    tickLabels: {fontSize: 7.5}
                                                }}
                                            />
                                            <VictoryLegend x={50} y={20}
                                                           orientation="horizontal"
                                                           gutter={20}
                                                           style={{
                                                               border: {stroke: "black"},
                                                               title: {fontSize: 10},
                                                               labels: {fontSize: 7.5}
                                                           }}
                                                           data={[
                                                               {name: "Rent", symbol: {fill: "red"},},
                                                               {
                                                                   name: "Co-Ownership Expenditure",
                                                                   symbol: {fill: "blue"}
                                                               },
                                                               {name: "Asset Value", symbol: {fill: "green"}}
                                                           ]}
                                            />
                                            <VictoryLine
                                                data={this.state.chart_rent}
                                                style={{data: {stroke: "red"}}}
                                            />
                                            <VictoryLine
                                                data={this.state.chart_expenditure}
                                                style={{data: {stroke: "blue"}}}

                                            />
                                            <VictoryLine
                                                data={this.state.chart_asset_value}
                                                style={{data: {stroke: "green"}}}

                                            />
                                        </VictoryChart>
                                    </Box>
                                </div>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Calculator;
